import * as React from 'react';

import MainLayout from 'components/Layout/MainLayout';
import MySEO from 'shared/MySEO';
import FixedTopBarScholarshipScheduleNOffline from '../shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import { HeaderLandingLuyenThi } from '../components/LandingLuyenThi/components/HeaderLandingLuyenThi';
import { LandingLuyenThiBannerLayout } from '../components/LandingLuyenThi/sections/LandingLuyenThiIELTSBanner';
import { withMarketingSection } from '../components/withMarketingSection';
import { BannerLuyenThi } from '../components/LandingLuyenThi/components/BannerLuyenThi';
import styled from 'styled-components';
import { BenefitList } from '../components/LandingLuyenThi/components/BenefitList';
import { useCoursesWithTeacher } from '../shared/CourseLanding/Courses';
import { CourseList } from '../components/LandingLuyenThi/components/CourseList';
import { WhyLearnAtDOLSection } from '../components/LandingLuyenThi/components/WhyLearnAtDOL';
import { fromScreen } from '../utils/media-query/responsive.util';
import { LandingSectionLayout } from '../components/LandingLuyenThi/sections/LandingSectionLayout';
import { LandingSectionTitle } from '../shared/Layout/SectionTitle';
import { LevelContext } from '../shared/Marketing/Online/DolRegister/components/withProgramByLevel';

const BannerLayout = withMarketingSection(
  styled.div`
    margin-top: 40px;

    display: grid;
    grid-row-gap: 40px;

    ${fromScreen(768)} {
      grid-row-gap: 80px;
      margin-top: 80px;
    }
  `,
  {
    style: {
      padding: 0,
      position: 'relative',
      zIndex: 1
    },
    selfish: true
  },
  { maxWidth: 1080 }
);

const mobileGroupCourses = [
  {
    name: '🚀 Các khoá SAT tại DOL',
    levels: ['S.InterV', 'S.InterM', 'S.FdnV', 'S.AdvV', 'S.AdvM', 'S.Scr']
  }
];
const desktopGroupCourses = [
  {
    name: '🚀 Các khoá SAT tại DOL',
    levels: ['S.InterV', 'S.InterM', 'S.FdnV', 'S.AdvV', 'S.AdvM', 'S.Scr']
  }
];
const bannerData = {
  titles: ['Luyện thi SAT', 'Tư Duy - Cấp Tốc', 'Đảm Bảo Đầu Ra'],
  description: ['Phương pháp Linearthinking, giáo viên giỏi và tận tâm. '],
  imgHandle: 'ie0zCSpSOWLw0j8iMuRA'
};
const benefitList = [
  {
    icon: 'yL5V3nQrSfihakEOWesH',
    text: 'Linearthinking'
  },
  {
    icon: 'JHpFBVM8RmSCXLA1hhjY',
    text: 'Tăng điểm siêu tốc'
  },
  {
    icon: 'PFnY1NKbTCmrXSA8Pekt',
    text: 'Giáo viên giỏi và có tâm'
  },
  {
    icon: 'hkcCAGjRTzSZeJ5oJry4',
    text: 'SAT không còn là nỗi lo'
  },
  {
    icon: 'YBXWslUmQxKXoJRAca90',
    text: 'Cơ sở vật chất hiện đại'
  }
];
const reasonsLearnAtDOL = [
  {
    image: 'xegQU3S0S1q8MAtrk7i6',
    title: 'Phương pháp Linearthinking được cục SHTT cấp chứng nhận',
    description:
      'Phương pháp Linearthinking được tạo ra từ tư duy toán học, kỹ thuật siêu trí nhớ và nghiên cứu ảnh hưởng của việc học Tiếng Việt lên Tiếng Anh để cho ra đời phương pháp học Tiếng Anh tư duy, dễ dàng, học từ bản chất, và từ đó tăng điểm SAT siêu tốc.',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Xem video Linear in SAT',
        modalTitle: 'Video bài giảng Linear in SAT',
        url: 'https://www.youtube.com/embed/DqVp3dXzFPw'
      }
    }
  },
  {
    image: 'SZLAudErRwiuV8VG1R3z',
    title: 'Tăng điểm siêu tốc',
    description:
      'Chương trình SAT của DOL là sự kết hợp 10 năm kinh nghiệm của thầy Hùng và Linearthinking do anh Lực tạo ra, để cho ra đời 1 chương trình tăng điểm siêu tốc và lại còn cực kỳ hấp dẫn.'
  },
  {
    image: 'BOhO0LxTgq9k0FdXWFOe',
    title: 'Giáo viên giỏi và có tâm',
    description:
      'SAT/GMAT/GRE hiện được giảng dạy bởi thầy Hùng SAT 2320/2400 (online) và thầy Minh IELTS 9.0, SAT 2300/2400 (offline HCM). Cả 2 thầy đều có nhiều năm kinh nghiệm và rất tận tâm với học viên.',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Thầy Hùng nói về triết lý giáo dục',
        modalTitle: 'Video thầy Hùng nói về triết lý giáo dục',
        url: 'https://www.youtube.com/embed/6I3FNcgXlII'
      }
    }
  },
  {
    image: 'PrXWulOuSm26o4QdVqVB',
    title: 'Đảm bảo đầu ra',
    description:
      'DOL là một trong những đơn vị đầu tiên dám cam kết đầu ra của chương trình khó như SAT. Để được vậy, phương pháp Linearthinking và chất lượng giảng dạy của DOL là khác biệt.'
  },
  {
    image: 'HyO3bvvYRqGMqEcExLzU',
    title: 'Luôn đặt học viên lên hàng đầu',
    description:
      'DOL luôn theo tiêu chí student-centric (tập trung vào học viên). Mỗi học viên sẽ được giáo viên cung cấp lộ trình học và theo sát quá trình tiến bộ. Giáo viên luôn hỗ trợ học viên sau giờ học.'
  },
  {
    image: '7kmmRCQhSTG49wCX3NuR',
    title: 'Cơ sở vật chất hiện đại',
    description:
      'DOL đầu tư vào cơ sở vật chất “xịn xò", hiện đại nhất từ phòng học thông thoáng và sạch sẽ, phòng tự học yên tĩnh, sảnh chờ, để tạo môi trường học thoải mái và tiện nghi nhất cho học viên.',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Xem video',
        modalTitle: 'Video nền tảng công nghệ',
        url: 'https://www.youtube.com/embed/G6TvFZDua48'
      }
    }
  }
];

const IELTSCourseList = withMarketingSection(
  CourseList,
  {
    style: {
      padding: 0,
      position: 'relative',
      zIndex: 2
    },
    selfish: true
  },
  { maxWidth: 1080 }
);
const WhyLearnAtDOLList = withMarketingSection(
  WhyLearnAtDOLSection,
  {
    style: {
      padding: 0,
      position: 'relative',
      zIndex: 2
    },
    selfish: true
  },
  { maxWidth: 1080 }
);
const TitleLayout = styled.div`
  padding-bottom: 24px;

  ${fromScreen(1366)} {
    padding-bottom: 8px;
  }
`;

const StudentCoursePage = () => {
  const { courseWithTeachers } = useCoursesWithTeacher();
  return (
    <LevelContext.Provider value="SAT">
      <MainLayout
        renderCustomHeader={() => <HeaderLandingLuyenThi />}
        noHeader
        hideSideCTA
        noFooter
      >
        <MySEO
          title="Luyện thi SAT Tư Duy - Siêu Cấp Tốc - Đảm bảo đầu ra | DOL English - Anh Ngữ Đình Lực"
          description="Các khóa Luyện thi SAT Tư Duy - Siêu Cấp Tốc - Đảm bảo đầu ra tại DOL English - Anh Ngữ Đình Lực với Linearthinking độc quyền, đội ngũ giáo viên tận tâm và nền tảng công nghệ tiên tiến giúp học viên tăng điểm SAT siêu tốc trong thời gian ngắn để SAT không còn là nỗi lo cho ước mơ du học của bạn"
        />
        <FixedTopBarScholarshipScheduleNOffline />

        <LandingSectionLayout>
          <LandingLuyenThiBannerLayout
            content={
              <BannerLayout>
                <BannerLuyenThi data={bannerData} />
                <BenefitList data={benefitList} />
              </BannerLayout>
            }
          />

          <IELTSCourseList
            courses={courseWithTeachers}
            desktopGroupCourses={desktopGroupCourses}
            mobileGroupCourses={mobileGroupCourses}
            title={
              <TitleLayout>
                <LandingSectionTitle>
                  🚀 Các khoá SAT tại DOL
                </LandingSectionTitle>
              </TitleLayout>
            }
          />

          <WhyLearnAtDOLList
            title="👌 6 lý do phải học SAT tại DOL"
            reasons={reasonsLearnAtDOL}
          />
        </LandingSectionLayout>
      </MainLayout>
    </LevelContext.Provider>
  );
};

export default StudentCoursePage;
